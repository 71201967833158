.container.subpage-container {
  height: auto;
  width: 100vw;
  flex-wrap: wrap;
}

.subpage-header {
  width: 100vw;
  height: 65vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (min-width:1201px) {
  .container.subpage-container.celebrating-banner .subpage-header{
    background-size: auto 75vh;
      background-position: 50% 70%;
  }
}

.subpage-title-container {
  width: calc(100vw - 70px);
  margin-left: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.subpage-title {
  font-size: 90px;
  font-family: "Garamond-Bold";
  color: white;
  background-color: var(--color-azul);
  padding: 5px 30px;
  margin: 0;
  text-align: center;
  white-space: pre-line;
}

.subpage-subtitle {
  font-size: 38px;
  font-family: "Garamond";
  font-weight: 100;
  background-color: white;
  color: var(--color-azul);
  padding: 3px 20px;
  margin: 0;
  margin-top: -5px;
  text-align: center;
}
.subpage-container .general-text {
  font-size: 19px !important;
  line-height: 28px !important;
}
.subpage-content {
  height: auto;
  width: 100vw;
  padding-bottom: 60px;
}
.subpage-recuadro {
  background-color: white;
  width: 70vw;
  margin-left: calc((30vw - 70px) / 2 + 70px);
  margin-top: 60px;
  padding: 40px;
  box-sizing: border-box;
  min-height: calc(18vw + 75px);
  /*
  transform:scale(1);
  box-shadow: none;
  transition:all linear .5s;*/
}
/*
.subpage-recuadro.face-act{
  transform:scale(1.1);
  box-shadow: 0 0 10px rgba(1, 48, 70, 0.5);
  transition:all linear .5s;
  transition-delay:1s;
}*/



.subpage-recuadro .float {
  width: 42%;
  height: auto;
  margin:0;
  margin-right: 40px;
  float: left;
  margin-bottom: 5px;
  
}
.subpage-recuadro .float img {
  width: 100%;
  height: auto;
  
}
.subpage-recuadro .float figcaption {
  text-align: center;
  font-size:17px;
  
}

.subpage-recuadro .float.with-border{
  border:1px solid var(--color-gris-claro);
}

.subpage-recuadro img.full {
  width: 100%;
  height: auto;
}

.subpage-recuadro p {
  margin-top: 0;
}
/*
.subpage-recuadro p:first-line,
.subpage-container .subpage-rec-title::first-line {
  line-height: 100% !important;
}*/

.subpage-recuadro p:last-child {
  margin-bottom: 0;
}
.subpage-recuadro.no-min-height {
  min-height: auto !important;
}
.subpage-container .link-int-page {
  text-decoration: underline !important;
  color: var(--color-azul-links) !important;
}
.subpage-container ul {
  width: 50%;
  margin: 10px auto 40px auto;
  list-style-type: none;
}
.subpage-container ul.limitless-list{
  width: 30%;
  margin-left:330px;
  display:none;
}
.subpage-container .subpage-rec-title {
  font-size: 24px;
  font-family: "Garamond-bold";
  color: var(--color-azul) !important;
}
.subpage-container .subpage-rec-subtitle {
  font-size: 20px;
  color: var(--color-azul) !important;
  font-weight: 100;
}


/*CAROUSEL*/
.subpage-container .carousel-img {
  height: 30vw;
  transition: all linear 0.5s;
  opacity: 1;
}
.subpage-container .slide:not(.selected) .carousel-img {
  height: 26vw;
  margin-top: 2vw;
  transition: all linear 0.5s;
  opacity: 0.6;
}

.subpage-container .carousel-slider {
  margin: auto !important;
  height: 30vw !important;
  padding-bottom: 50px !important;
}
.subpage-container .carousel-container {
  margin: 40px 0;
}
.slider-wrapper.axis-horizontal {
  height: 100% !important;
}
.list-disc li {
  list-style-type: disc;
}
.carousel .control-dots .dot {
  background: white !important;
  border: 1px solid var(--color-azul) !important;
  opacity: 1 !important;
  box-shadow: none !important;
}
.carousel .control-dots .dot.selected {
  background: var(--color-azul) !important;
  border: 1px solid var(--color-azul) !important;
  opacity: 1 !important;
}

.carousel .control-dots .dot {
  background: white !important;
  border: 1px solid var(--color-azul) !important;
  opacity: 1 !important;
  box-shadow: none !important;
  width: 12px !important;
  height: 12px !important;
}
.carousel .control-dots .dot.selected {
  background: var(--color-azul) !important;
  border: 1px solid var(--color-azul) !important;
  opacity: 1 !important;
}
.carousel.carousel-slider .control-arrow {
  height: calc(100% - 50px);
}
/*END CAROUSEL*/

/*RELATED CONTENT*/
.subpage-recuadro.related-recuadro{
  min-height:auto;
}
.related-group-title{
  text-align: center;
}
.related-group-container{
  display:flex;
  justify-content: center;
  gap:20px;
  margin-top:40px;
  flex-wrap:wrap;
}
.related-item{
  cursor:pointer;
  transform:scale(1);
  transition:all linear .5s;
}
.related-item:hover{
  transform:scale(1.1);
  transition:all linear .5s;
}
.related-item .related-item-img{
  border-radius:50%;
  width:120px;
  height:120px;
  background-size:cover;
  background-position:center;
  margin:auto;
}
.related-item h5{
  text-align: center;
  width:140px;
  padding-top:10px;
}
@media screen and (max-width:1400px){
  .related-item .related-item-img{
    width:90px;
    height:90px;
  }
  .related-item h5{
    width:110px;
  }
}

@media screen and (max-width:1200px) and (orientation:portrait){
  .related-item .related-item-img{
    width:150px;
    height:150px;
  }
  .related-item h5{
    width:150px;
  }
  .related-group-container{
    gap:60px;
  }
}


/*END OF RELATED CONTEND*/
/*GO HOME BTN*/
.go-back-btn{
  position:absolute;
  background-color:var(--color-azul-con-trans);
  color:#a4b5be;
  padding:10px;
  top:calc(65vh + 60px);
  left:calc(70px + 3vw);
  cursor:pointer;
}
.go-back-btn:hover{
  background-color:var(--color-azul);
}
.go-back-btn svg{
  padding-right:10px;
}
@media screen and (max-width:1200px){
  .go-back-btn{
    display:none;
  }
}
@media screen and (min-width:1600px){
  .go-back-btn{
    left:calc(70px + 10vw);
  }
}
/*END OF GO HOME BTN*/

@media screen and  (min-width: 1400px) {
  /*FLEX REC: RECUADROS Q NO LLEVARAN LA IMG EN FLOAT SINO Q SE ACOMODARAN CON FLEXBOX*/
  .flex-rec {
    display: flex;
  }
  .flex-rec .float {
    float: none !important;
    height: 18vw !important;
  }
  /*ARREGLO PARA MOZILLA SOLO DESKTOP*/
  .subpage-recuadro div.text-container {
    width: calc(58% - 40px);
  }
}

@media screen and  (min-width: 1600px) {
  .subpage-recuadro {
    background-color: white;
    width: 60vw;
    margin-left: calc((40vw - 70px) / 2 + 70px);
    margin-top: 60px;
    padding: 40px;
    box-sizing: border-box;
    min-height: calc(15.5vw + 75px);
  }
  .flex-rec .float {
    height: 15.5vw !important;
  }
}

@media screen and  (max-width: 1200px){
  .subpage-header {
    width: 100vw;
    height: 40vh;
    padding-top: 80px;
    box-sizing: border-box;
    margin-top:98px;
  }
  .subpage-title {
    font-size: 40px;
    width: 80vw;
    padding: 3px 20px;
    /*background-color:rgb(1, 48, 70, .7);/*azul con transparencia*/
  }

  .subpage-subtitle {
    width: 70vw;
    font-size:30px;
    margin-top: -3px;
    /*background-color: rgb(255,255,255, .7);/*blanco con transparencia*/
  }
  .subpage-recuadro {
    width: 90vw;
    margin-left: 5vw;
  }
  .subpage-content.bg-settings{
    background-size:120% !important;
    background-repeat:repeat !important;
  }
  
  .subpage-title-container {
    width: 100vw;
    margin-left: 0;
  }
  .subpage-container ul {
    width: 500px;
    padding-left: 0;
    max-width: 100%;
  }
  .subpage-container ul.list-disc {
    padding-left: 20px;
    box-sizing: border-box;
  }
  /*CAROUSEL*/
  .subpage-container .carousel-img {
    height: 45vw;
  }
  .subpage-container .slide:not(.selected) .carousel-img {
    height: 45vw;
    margin-top: 0vw;
  }
  
  .subpage-container .carousel-slider {
    height: 45vw !important;
  }
  
  /*END CAROUSEL*/
  
}
@media screen and  (max-width: 1200px) and (orientation:portrait){
  .subpage-recuadro .float {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  
}
@media screen and (max-width:400px){
  .subpage-title{
    font-size: 30px;
    box-sizing: border-box;
  }
  .subpage-subtitle {
      font-size: 20px;
      box-sizing: border-box;
  }
  .subpage-container .general-text {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .subpage-recuadro .float figcaption {
    font-size:15px;
    
  }
}

/*ARREGLO CAMBIO DE POSICION DE TITULOS*/
@media screen and (min-width:1201px){
  .subpage-title-container {
    position: absolute;
    top: 0;
    height: 65vh;
  }
}
@media screen and (max-width:1200px){
  .subpage-title-container {
    padding-top:40px;
  }
  .container.subpage-container.bg-settings {
    background-size: 120% !important;
    background-repeat: repeat !important;
}
}


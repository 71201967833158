.menu-full-container {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    min-height:100vh;
    height: 150vh;
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    z-index: 9;
    justify-content: center;
    box-sizing: border-box;
    padding-top:120px;
    align-items:flex-start;
    overflow-y:auto;
  }
  .menu-nav-container{
    height: 100%;
    width:90%;
    padding-bottom:300px;
  }
  .menu-nav-container nav{
    height:100%;
    width:100%;
  }
  .menu-nav-container a{
    color:var(--color-azul) !important;
  }
  .menu-nav-container ul{
    height:100%;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    gap:10px;
    align-items: center;
    font-size:24px;
    font-family:'Garamond';
    list-style: none;
    color:var(--color-azul);
    padding-left:0;
  }
  .menu-nav-container li h1{
    cursor:pointer;
    transform: scale(1);
    transition:all .5s linear;
    font-size:24px;
    font-weight: 600;
    margin:0;
    text-align: center;
  }

  .menu-nav-container .menu-item:hover{
    text-align:center;
  }

  .menu-nav-container .submenu{
    font-size:20px;
    gap:0px;
    padding-left:0;
  }
  .menu-nav-container .submenu h2{
    border-bottom:1px dotted var(--color-azul);
    padding-top:10px;
    padding-bottom:10px;
  }
  .menu-nav-container .submenu h2,.menu-nav-container .sub-submenu{
    cursor:pointer;
    transform: scale(1);
    transition:all .5s linear;
    font-size:18px;
    font-weight: 400;
    margin:0;
  }
  .menu-nav-container .sub-submenu{
    font-size:16px;
    /*
    display:flex;
    align-items:center;
    justify-content:center;
    gap:10px;*/
    position:relative;
  }
  .menu-nav-container .menu-title:hover,
  .menu-nav-container .sub-submenu:hover{
    transform: scale(1.1);
    transition:all .5s linear;
  }

  .menu-nav-container .submenu ul {
    gap: 10px; 
    padding-top: 10px;
}

  .menu-close-btn{
    position:fixed;
    top: 35px;
    right: 20px;
    width:30px;
    height:30px;
    cursor:pointer;
    z-index:9;
  }
  .menu-close-btn svg{
    width:25px;
    height:25px;
  }
  .menu-close-btn svg path{
    fill:var(--color-azul)
  }
  .menu-logo-container{
    position:absolute;
    width:100vw;
    left:0;
    top:30px;
    box-sizing: border-box;
    text-align: center;
  }
  .menu-logo{
    width:80px;
    margin:auto;
    /*display:none;*/
  }
  .menu-title{
    /*
    display:flex;
    justify-content:center;
    gap:10px;
    align-items:center;*/
    position:relative;
  }
  .menu-falkor-too{
    position:absolute;
    /*right:30%;*/
    right:-130px;
    bottom:29%;
    width:100px;
    opacity:0;
  }
  .menu-falkor{
    position:absolute;
    /*left:30%;*/
    left:-80px;
    bottom:38%;
    width:75px;
    opacity:0;
  }
  li .menu-title:hover .menu-falkor-too,
  .submenu li:hover .menu-falkor-too{
    opacity:1;
    /*right:-50px;
    transition:right .25s linear;*/
  }
  li .menu-title:hover .menu-falkor,
  .submenu li:hover .menu-falkor{
    opacity:1;
    /*left:-50px;
    transition:left .25s linear;*/
  }
  .menu-arrow{
    /*display:none;*/
    display: initial;
    width: 7px;
    height: auto;
    position: absolute;
    right: -20px;
    bottom: 29%;
    transform: rotate(90deg);
  }
  /*codigo especial para item "soi remastered"*/
  .menu-special-title{
    width:120px !important;
    margin:auto;
  }
  /*fin de codigo especial para item "soi remastered"*/
/*
  .submenu li:hover .menu-arrow{
    display:none;
  }*/


  /*responsive big advices*/
@media screen and (min-height:990px) and (min-width:1600px){

  .menu-nav-container {
    height: 80%;
  }
  .menu-nav-container ul{
    gap:25px;
  }
  .menu-nav-container li h1{
    font-size:30px;
  }
 
  .menu-nav-container .submenu h2,.menu-nav-container .sub-submenu{
    font-size:22px;
  }
  .menu-nav-container .sub-submenu{
    font-size:18px;
  }

  .menu-falkor-too{
    right:-130px;
    bottom:27%;
    width:120px;
  }
  .menu-falkor{
    left:-100px;
    bottom:36%;
    width:90px;
  }
  .menu-special-title {
    width: 150px !important;
}

}
/*RESPONSIVE MOBILE*/
  @media screen and (max-width:1200px){
    /*
    .menu-full-container {
      box-sizing: border-box;
      padding-top:120px;
      min-height: 100vh;
      height: 150vh;
      align-items:flex-start;
      overflow-y:auto;
    }*/
    .menu-title{
      text-align: center;
    }
    /*
    .menu-close-btn {
      top: 35px;
      right: 20px;
      z-index:9;
      position:fixed;
    }*/
    .menu-close-btn svg{
      height:30px;
      width:30px;
    }
    /*
    .menu-nav-container {
      height: 100%;
      width:90%;
      padding-bottom:300px;
    }*/
    .menu-falkor, .menu-falkor-too{
      display:none !important;
    }
    .menu-arrow{
      display: initial;
      width: 7px;
      height: auto;
      position: absolute;
      right: -20px;
      bottom: 29%;
      transform: rotate(90deg);
    }
    .submenu li:hover .menu-arrow{
      display:initial;
    }
    .menu-close-btn {
      top: 20px;
      right: 25px;
    }
    .menu-logo-container{
      top:40px;
    }


  }
  /*RESPONSIVE LANDSCAPE*/
  @media screen and (max-width:1200px) and (orientation: landscape) {
      .menu-full-container {
        height: 200vh;
    }
  }

.panel{
    position:relative;
}
.home-panel{
    z-index:0;
}
.home-img{
    height:100vh;
    width:30%;
    background-position:center;
    background-size: cover;
    /*z-index:1;*/
}
.home-principal-content{
    height:100vh;
    width:40%;
    min-width: 650px;
    background-position:center;
    background-size: cover;
    padding: 6vh 6vw;
    box-sizing: border-box;
    z-index:1;
}
.home-float-arrows-container{
    position:absolute;
    bottom:0vh;
    right:0;
    width:700%;
    height:19vh;
    display:flex;
    justify-content: center;
    align-items: center;
    gap:20vw;
    z-index:7;

}
.home-float-arrow{
    height:100%;
}
/*home center*/
.home-icon-container{
    margin-top:0;
    margin-bottom:30px;
    position:relative;
    width:160px;
    height:160px;
    margin:auto;
}
.home-title{
    text-align: left;
}
.home-logo{
    height:160px;
    margin:auto;
}
.home-logo-float{
    position:absolute;
    height:50px;
    top:54px;
    left:46px;
}

.home-mouse-container{
    justify-content: center;
    gap:40px;
    margin-top:5vh;
}
.home-mouse-item-container{
    display:flex;
}
.home-mouse-item-container{
    justify-content:flex-end;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items:center;
    gap: 10px;
}
.home-mouse-item-container.right{
    justify-content:start;
}

.home-mouse-item-container h2{
    font-size:62px;
    color:var(--color-azul);
    font-family:'Garamond-Bold';
    font-weight: 600;
    margin:0;
}
.home-mouse{
    width:30px;
}
.home-arrow{
    width:50px;
}
@media screen and (min-width:1201px){
    .home-arrow{
        opacity:0;
    }
}
.home-mouse-item-container p{
    font-family:'Garamond-Bold';
    margin:0;
    width:180px;
    text-align:left;
}
.home-mouse-item-container.right p{
    text-align:right;
}


/*falkor images*/
.falkor-img, .falkor-too-img{
    width:0; 
    height:150px;
    background-size:300px;
    background-repeat:no-repeat;
    position:fixed;
    bottom:3vh;
    pointer-events: none;
    
}
.falkor-img{
    background-position:right;
    background-size:250px;
    right:52vw;
    bottom:2.3vh;
}
.falkor-too-img{
    background-position:left;
    left:50vw;
}
.hiddenFalkor{
    visibility: hidden;
    
}
.falkor-img-static, .falkor-too-img-static{
    width:300px; 
    height:150px;
    background-size:300px;
    background-repeat:no-repeat;
    position:absolute;
    bottom:3vh;
    z-index:3;
}
.falkor-img-static{
    background-position:right;
    width:250px; 
    background-size:250px;
    right:52vw;
    bottom:2.3vh;
}
.falkor-too-img-static{
    background-position:left;
    left:50vw;
}

/*timeline*/
.timeline-container{
    position:absolute;
    bottom:5vh;
    right:-10vw;
    width:710%;
    height:50px;
    z-index:8;
    background-size:710vw;
    background-repeat: no-repeat;
    background-position: bottom;
    display:flex;
    justify-content: flex-start;
    align-items: flex-end;
}

/*botones de navegacion*/
.home-nav-btn{
    position:fixed;
    bottom:calc(50vh - 25px);
    width:50px;
    height:auto;
    opacity:1;
    padding:10px;
    cursor:pointer;
}
.home-nav-btn:hover{
    opacity:.5;
}
.home-nav-btn.prev-nav-btn{
    left:5vw;
}
.home-nav-btn.next-nav-btn{
    right:5vw;
}


/*home future1 section1*/
.future1-panel{
    justify-content: flex-start;
}
.future1-panel .future1-section1{
    width:38%;
    margin-left:0;
    height:100.5%;
    background-size: cover;
    margin-top: -5px;
    position:relative;
    background-position: center;
}


/*home future1 section2*/
.future1-panel .future1-section2{
    width:62%;
    margin-left:0;
    height:100.5%;
    background-size: cover;
    background-position:center;
    /*margin-top: -5px;
    display:flex;
    justify-content: flex-end;*/
    position:relative;
    color:white;
    font-family: 'Garamond';
    font-weight: 400;
    text-align: left;
}
.future1-panel .future1-section2 .text-container{
    display:flex;
    flex-direction: column;
    width:420px;
    margin-left:0;
    margin-right:auto;
    padding-left:5%;
}

.future1-panel .future1-section2 .future1-title{
    /*opacity:0;*/
}
.future1-panel .future1-section2 .future1-subtitle{
    /*opacity:0;*/
}
.future1-panel .future1-section2 .future1-description{
    width: 420px;
}
.future1-panel-card-container .future1-description{
    overflow:hidden;
    /*height:0px;*/
}


.future1-section2 .cards-future1-container{
    display:flex;
    width:90%;
    justify-content:space-between;
    flex-wrap: wrap;
    position:absolute;
    bottom:15vh;
    left:5%;
}
.future1-section2 .text-container .enter-arrow{
    align-self: flex-end;
    opacity:0;
    margin-right:10px;
}


/*future2 section*/
.future2-vid-container{
    width:24vw;/* 50.27vh;*/
    height:100vh;
    position:relative;
    cursor:pointer;
    overflow: hidden;
}
.future2-vid-container video {
    height: 100%;
    position: absolute;
    left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%); /* % of current element */
}
.canvas {
    width: auto;
    height:100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


/*past1 panel*/
.past1-panel{
    background-size:105%;
    background-position:center;
    align-items:flex-start;
    /*width:138%;*/
    width:100%;
    /*margin-left:-38vw;*/
}
.past1-panel .future2-vid-container{
    width: 100vw;
    height:120vh;
    position: absolute;
    left: -38vw;
}
#bythenumbers video{
    height:100%;
    width:auto;
    /*
    width:100%;
    height:auto;
    min-height:120vh;
    margin-top:-20px;*/
}

#bythenumbers video.ajustado{
    width:100%;
    height:auto;
    min-height:120vh;
    margin-top:-20px;
}
.past1-panel .numbers-degradado{
    position:absolute;
    width:100%;
    height:100vh;
    /*background-size:cover;
    background-position:center;*/
    background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
.past1-panel .past1-info-container{
    position:relative;
    width:90vw;
    height:80vh;
    margin-left:5vw;
    opacity:1;
}

.past1-panel .past1-title{
    text-align: left;
    color: var(--color-azul);
    font-family: 'Garamond';
    font-weight: 400;
    font-size: 74px;
    line-height: 80px;
    padding-right: 50px;
    margin-top:0;
    margin-bottom:30px;
}
/*seccion by the numbers*/
.past1-panel .past1-numbers-container{
    width:90%;
    height:80%;
    margin:auto;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
}
.past1-panel .past1-numbers-container .numbers-row{
    display:flex;
    height:30%;
    width:100%;
    justify-content: space-evenly;
}
.past1-panel .past1-numbers-container .numbers-item{
    width:22%;
    text-align:center;
}
.past1-panel .past1-numbers-container .numbers-text{
    /*font-size:18px !important;*/
    text-align:center;
}
.past1-panel .past1-numbers-container img{
    width:80px;
    height:auto;
    margin:auto;
}



/*end of section by the numbers*/
/*posicion de card de past1*/
.past1-card-container{
    width:38%;
    margin-left:0;
    height:100.5%;
    background-size: cover;
    margin-top: -5px;
    position:relative;
}

.past1-card-container{
    margin-right:0;
    margin-left:auto !important;
}

/*past 2 section*/
.past2-panel{
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    justify-content: flex-start;
    align-items:flex-start;
    flex-direction:column;
}
.past2-panel .title-container{
    width:62%;
}
.past2-panel .past2-flex-container{
    width:56%;
    margin-left:3%;
    margin-top:10px;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.past2-panel .past2-item{
    width:48%;
    padding-bottom: 10px;
}
.past2-panel .past2-card{
    display:flex;
    align-items:center;
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    padding:10px 20px;
    box-sizing: border-box;
    gap:20px;
    margin-bottom:20px;
    cursor:pointer;
}
.past2-panel .past2-card img{
    width:80px;
    height:80px;
    margin:auto;
}
.past2-panel .card-title{
    text-align:left !important;
    width: 70%;
}





/*past3*/
.past3-panel{
    justify-content: flex-start;
    align-items:flex-start;
}
.past3-panel .past3-section1{
    width:68%;
    height:100vh;
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
}
.past3-panel .title-container{
    width:88%;
    margin-left:6%;
}
.past3-section1 .general-text{
    margin-top:10px;
}
.past3-panel .cards-container{
    width:88%;
    margin-left:6%;
    display:flex;
    justify-content: space-between;
    margin-top:5vh;
}

.past3-panel .past3-section2{
    width:32%;
}
.past3-panel .future2-vid-container{
    width: 100%;
}




/*past4*/

.past4-panel{
    justify-content: flex-start;
    align-items:flex-start;
}
.past4-panel .past4-section1{
    width:calc(100% - 52.066vh);
    height:100vh;
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
}
.past4-panel .past4-section2{
    width:52.066vh;
    height:100vh;
}
.past4-panel .title-container{
    width:82%;
    margin-left:12%;
}
.past4-section1 .general-text{
    margin-top:10px;
}
.past4-panel .cards-container{
    width:82%;
    margin-left:12%;
    display:flex;
    justify-content: space-between;
    margin-top:5vh;
    flex-wrap: wrap;
}

/*
.past2-panel{
    position:relative;
}
.past2-panel .past2-container{
    position:absolute;
    background-size:cover;
    background-position-x:center;
    width:200vw;
    height:101vh;
    left:-100vw;
    top:-1vh;
}*/



/*responsive big advices*/
@media screen and (min-height:990px) and (min-width:1600px){
    .home-mouse-container {
        margin-top: 8vh;
    }
    .home-icon-container {
        width: 200px;
        height: 200px;
    }
    .home-logo {
        height: 200px;
    }
    .home-logo-float {
        height: 65px;
        top: 66px;
        left: 54px;
    }
    .home-mouse-item-container h2{
        font-size:75px;
    }
    .home-arrow {
        width: 70px;
    }
    .future1-panel .future1-section2 .future1-description {
        width: 480px;
        margin-bottom:10px;
    }
    .future1-panel .future1-section2 .text-container {
        width: 480px;
    }
    .past2-panel .past2-card{
        margin-bottom:30px;
        gap:30px;
    }
    .past2-panel .past2-card img {
        width: 110px;
        height: 110px;
    }
    .home-principal-content {
        padding: 10vh 4vw;
    }
    .future1-section2 .cards-future1-container{
        bottom:16vh;
    }
    /*falkor images*/
    .falkor-img, .falkor-too-img{
        width:0; 
        height:150px;
        background-size:350px;
        background-repeat:no-repeat;
        position:fixed;
        bottom:4.5vh;
        
    }
    .falkor-img{
        background-position:right;
        right:52vw;
        background-size:300px;
        bottom:3.9vh;
    }


    .falkor-img-static, .falkor-too-img-static{
        width:350px; 
        height:150px;
        background-size:350px;
        background-repeat:no-repeat;
        position:absolute;
        bottom:4.5vh;
        z-index:3;
    }
    .falkor-img-static{
        width:300px;
        background-size:300px;
        bottom:3.9vh;
    }


}


@media screen and (max-width:1300px) and (min-width:1201px){

    /*escalar by the numbers*/
    .past1-numbers-container .numbers-item{
        transform:scale(.9);
    }
    /*acomodar margin top de home principal content*/
    .home-principal-content {
        padding-top: 5vh;
    }


}
/*para caso 1280 x 600*/
@media screen and (max-width:1300px) and (max-height:620px){
    .past2-panel .past2-card img {
        width: 60px;
        height: 60px;
    }
    .future1-section2 .cards-future1-container {
        bottom: 12vh;
    }
    .home-principal-content {
        padding-top: 1vh;
    }
    .home-icon-container {
        width: 90px;
        height: 90px;
    }
    .home-logo-float {
        position: absolute;
        height: 30px;
        left: 25px;
        top: 31px;
    }
    .home-logo{
        height:90px;
        min-height: 90px;
    }
    .home-mouse-item-container h2 {
        font-size: 50px;
    }
}

/*RESPONSIVE MOBILE*/
@media screen and (max-width:1200px){
    /*PAST4*/
    .past4-panel{
        flex-wrap:wrap;
    }
    .past4-panel .past4-section1{
        padding-top:120px;
        height:auto;
    }
    .past4-panel .past4-section1, .past4-panel .past4-section2{
        width:100vw;
    }
    .past4-panel .title-container, .past4-panel .cards-container {
        width: 90%;
        margin-left: 5%;
        margin-bottom:3vh;
    }

    /*PAST3*/
    .past3-panel{
        flex-wrap:wrap;
    }
    .past3-panel .past3-section1, .past3-panel .past3-section2{
        width:100vw;
        height:auto;
    }
    .past3-panel .past3-section2 {
        height: 95vh;
        margin-top: -5px;
    }
    .past3-panel .title-container, .past3-panel .cards-container{
        width: 90%;
        margin-left: 5%;
    }
    .past3-panel .cards-container {
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom:60px;
    }

    /*PAST2*/
    .past2-panel{
        width:100vw;
        height:auto;
    }
    .past2-panel .title-container {
        width: 90%;
        margin-left:5%;
    }
    .past2-panel .past2-flex-container {
        width: 90%;
        margin-left: 5%;
        margin-top:0;
        margin-bottom:5vh;
    }
    .past2-panel .past2-item {
        width: 100%;
        padding-bottom: 20px;
    }

    /*PAST1*/
    .past1-panel{
        width:100vw;
        height:auto;
        flex-wrap:wrap;
    }
    .past1-panel #bythenumbers{
        width:100vw;
        height:auto;
        position:relative;
        left:0;
    }
    .past1-panel .past1-info-container {
        height: auto;
    }
    .past1-panel .past1-numbers-container {
        width: 100%;
        height:auto;
        padding-top:0px;
        padding-bottom:40px;
    }
    .past1-panel .past1-numbers-container .numbers-row {
        height: auto;
        flex-wrap: wrap;
    }
    .past1-panel .past1-numbers-container .numbers-item {
        width: 100%;
        padding-bottom:20px;
    }
    .past1-card-container {
        width: 100vw;
        height: auto;
        margin-top: 0;
    }
    .past1-panel .numbers-degradado{
        height:100%;
    }
    #bythenumbers video.ajustado {
        height: auto;
        min-height: 100%;
        width: auto;
        min-width: 100%;
    }
    
    /*CENTER*/
    .home-panel{
        flex-direction: column;
    }
    .home-principal-content {
        width: 100vw;
        min-width:100vw;
        height:calc(100vh - 140px);
        padding: 40px 8vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:20px;
    }
    .home-title-mobile-flex{
        display:flex;
        justify-content:space-between;
        align-items:center;
        gap:10px;
        padding-top:10px;
    }
    .home-img {
        height: 70px;
        width:100vw;
    }
    .home-principal-text{
        font-size:12px;
    }
    .home-icon-container {
        width: 90px;
        height: 90px;
        margin:0;
    }
    .home-logo-float {
        position: absolute;
        height: 30px;
        left: 25px;
        top: 31px;
    }
    .home-logo{
        height:90px;
        min-height: 90px;
    }
    .home-title-container {
        margin-bottom: 2vh;
    }
    .home-arrow{
        transform: rotate(90deg);
        position: absolute;
        top: 25px;
    }
    .left .home-arrow{
        position: initial;
        width: 40px;
        order:-1;
        margin-bottom: -10px;
    }
    .right .home-arrow{
        position: initial;
        width: 40px;
        margin-top:-10px;
    }
    .home-mouse-item-container{
        position: absolute;
        justify-content:center !important;
        gap:0;
        width: 100vw;
        left: 0;
        flex-direction:column;
    }
    .home-mouse-item-container.left{
        top: 100px;
    }
    .home-mouse-item-container.right{
        bottom: 100px;
    }
    .home-mouse-container {
        gap: 0;
        flex-direction: column;
        margin-top: 0;
    }
    .home-mouse-container p {
        width:140px;
    }
    .home-mouse-item-container h2 {
        font-size: 40px;
    }
    .home-mouse-item-container.left .flex-start h2{
        order:-1;
    }


    /*FUTURE1*/
    .future1-panel{
        width:100vw;
        height:auto;
        flex-wrap:wrap;
    }
    .future1-panel .future1-section1, .future1-panel .future1-section2{
        width:100vw;
        height:auto;
    }
    .future1-panel .future1-section1 {
        margin-top: -2px;
    }
    /*
    .future1-panel .future1-section2 .future1-subtitle,
    .future1-panel .future1-section2 .future1-title{
        opacity: 1;
    }*/
    .future1-panel .future1-section2 .text-container {
        width: 90vw;
    }
    .future1-panel-card-container .future1-description {
        height: auto;
        width:90vw !important;
        /*opacity:0;*/
    }
    .future1-section2 .text-container .enter-arrow {
        /*opacity: 1;*/
        margin-right: 0;
    }
    .future1-section2 .cards-future1-container {
        width: 90vw;
        position: initial;
        margin-left:5vw;
        margin-top:5vh;
        margin-bottom:5vh;
    }

    /*FUTURE2*/


    .future2-vid-container {
        width: 100vw;
        /*height: 100%;*/
        height:100vh;
    }
    .past3-panel .future2-vid-container {
        height:100%;
    }
    /*
    .future2-vid-container video{
        min-width: 100vw;
        height: auto;
        min-height: 100%;
    }
    .future2-panel .future2-vid-container{
        max-height: 100vh;
    }*/
     
}

@media screen and (max-width:413px){
    .home-principal-content {
        height:calc(100vh - 140px);
        padding: 40px 5vw;
    }
    .home-img {
        height: 70px;
    }
    .home-mouse-item-container.left{
        top: 70px;
    }
    .home-mouse-item-container.right{
        bottom: 70px;
    }
    

}

@media screen and (max-width:376px){
    .home-mouse-item-container.right {
        bottom: 30px;
        transform: scale(.8);
    }
    .home-mouse-item-container.left {
        top: 30px;
        transform: scale(.8);
    }
    .home-mouse-container p {
        text-align: center;
    }
}

/*RESPONSIVE LANDSCAPE*/
@media screen and (max-width:1200px) and (orientation: landscape) {
    .home-principal-content {
        min-height:600px;
    }
    .past2-panel .past2-item {
        width: 49%;
    }
    .past1-panel .past1-numbers-container .numbers-item {
        width: 24%;
    }

    .future2-vid-container {
       height:100%;
    }

    .future2-vid-container video{
        min-width: 100vw;
        height: auto;
        min-height: 100%;
    }
    .future2-panel .future2-vid-container{
        max-height: 100vh;
    }
}

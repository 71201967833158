
/*fonts*/
@font-face {
  font-family: "Garamond-Bold";
  src: url(../fonts/GARABD.TTF) format("truetype");
}
@font-face {
  font-family: "Garamond";
  src: url(../fonts/GARA.TTF) format("truetype");
}
@font-face {
  font-family: "Serif";
  src: url(../fonts/DMSerifDisplay-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Garamond-Italic";
  src: url(../fonts/EBGaramond-Italic-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family: "Garamond-Semibold";
  src: url(../fonts/Garamond_Semibold.otf) format("truetype");
}


/*colors*/
:root{
  --color-azul: #013046;
  --color-amarillo: #DBAC21;
  --color-gris: #333333;
  --color-gris-claro: #bfbfbf;
  --color-azul-links:#0000FF;
  --color-azul-con-trans: rgb(1, 48, 70, .8);

  --real100vh: 100vh;
  --real100vw: 100vw;
}

html, body {
  margin: 0;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  /*scroll-behavior:smooth;*/
}
.panel {
  width: 100%;
  height: 100vh;/*100%;*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  position: relative;
  box-sizing: border-box;
  padding: 0px;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

.container {
  width: 700%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.bg-settings{
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
}
.bg-position-right{
  background-position:right !important;
}

.mt-general{
  margin-top:6vh;
}
.mt-0{
  margin-top:0 !important;
}

.title {
    font-size:42px !important;
    font-family:"Garamond-Bold";
    color:var(--color-azul);
    margin-bottom:15px;
    margin-top:0;
}
.subtitle {
  font-size:22px !important;
  font-family:"Garamond-Bold";
  color:var(--color-azul);
  margin-top:0;
  margin-bottom:15px;
}
.general-text {
  font-size:16px !important;
  font-family:"Garamond";
  /*color:var(--color-gris);*/
  color:black;
  text-align: left;
  margin:0;
  line-height: 22px;
}
.general-text.numbers-text{
  font-size:18px !important;
}
.card-title{
  margin-top:0;
  margin-bottom:5px;
  font-size:36px !important;
  color:white;
  font-family:"Garamond-Bold";
}
span.falkor-format{
  font-family:'Garamond-Italic' !important;
  font-weight: 100 !important;
}
.enter-arrow{
    width:30px;
    cursor:pointer;
    /*
    position: absolute;
    right: 50px;
    bottom: 25px;*/
}
.text-white{
  color:white !important;
}
.text-bold{
  font-family:'Garamond-Bold';
}
.text-underlined{
  text-decoration: underline !important;
}
.blue-text{
  color:var(--color-azul-links);
}
#root{
  white-space: pre-line;
  /*box-sizing: border-box;*/
}

.flex{
  display:flex;
}
.flex-start{
  display:flex;
  flex-direction: column;
  align-items:flex-start;
  margin-left:5px;
}
.flex-end{
  display:flex;
  flex-direction: column;
  align-items:flex-end;
  margin-right:5px;
}
::-webkit-scrollbar {
  display: none;
}
a{
  text-decoration: none !important;
}
/*imagenes para transicion*/
.img-full-transition{
  position:fixed;
  background-size:cover;
  background-position:center;
  z-index:8;
}
@media screen and (min-height:900px) and (min-width:1400px){
  .general-text {
    font-size:18px !important;
}
.general-text.numbers-text{
  font-size:20px !important;
}
}
/*responsive big advices*/
@media screen and (min-height:990px) and (min-width:1600px){

  .general-text {
      font-size:22px !important;
      line-height: 28px;
  }
  .title {
    font-size:48px !important;
  }
  .subtitle {
    font-size:30px !important;
    margin-bottom:30px;
  }
  .mt-general{
    margin-top:8vh;
  }
  .card-title {
    font-size: 40px !important;
  }
  .enter-arrow{
    width:35px;
}

}
@media screen and (max-width:1500px){
  .card-title {
    font-size: 32px !important;
  }
}
@media screen and (max-width:1367px){
  .card-title {
    font-size: 28px !important;
  }
  .title{
    font-size:38px !important;
  }
  .subtitle{
    font-size:20px !important;
  }
  .general-text{
    font-size:16px !important;
  }

}
/*para caso 1280 x 600*/
@media screen and (max-width:1300px) and (max-height:620px){
  .mt-general{
      margin-top:1vh;
  }
}

/*RESPONSIVE MOBILE*/
@media screen and (max-width:1200px){
  .title{
    font-size:34px !important;
  }
  .mobile-container{
    overflow-x:hidden !important;
  }
  .container{
    width:100vw;
    height:auto;
    flex-wrap: wrap;
    margin-top:-10px;
  }
  .panel{
    width:100vw;
    height:auto;
  }
  .home-float-arrows-container, .timeline-container, .falkor-img, .falkor-too-img {
      display:none !important;
  }
  .general-text{
    font-size:18px !important;
  }
  /*arreglo especifico para mobile flechas center*/
  .flex-start, .flex-end {
    align-items: center;
  }
}

@media screen and (max-width:430px){
  .title {
    font-size:30px !important;
  }
}

@media screen and (max-width:400px){
    .general-text{
      font-size:16px !important;
    }
}

  /*RESPONSIVE LANDSCAPE*/
  @media screen and (max-width:1200px) and (orientation: landscape) {
    .mt-general{
      margin-top:5vh;
    }
    .general-text.numbers-text {
      font-size: 16px !important;
  }
}
.nav {
    position: fixed;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: 0%;
    z-index: 9998;
    display: flex;
    height: var(--real100vh);
    width:70px;
    justify-content: flex-start;
    background-color: rgba(255,255,255,0.5);
}
.principal-nav{
    display: flex;
    height: 100vh;
    width:50px;
    flex-direction: column;
    justify-content: flex-start;
    border-right:2px solid var(--color-azul);
}
.principal-nav div{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav .toggle-icon-container{
    height:15vh;
    border-bottom:2px solid var(--color-azul);
    cursor:pointer;
}
.nav .toggle-icon-container svg{
    width:25px;
    height:25px;
}
.nav .toggle-icon-container svg path{
    fill:var(--color-azul);
}

.nav .logo-container{
    height:70vh;
}
.nav .year-container{
    height:25vh;
    align-items: self-start;
}
.principal-nav h1{
    color:var(--color-azul);
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-size:30px;
    font-family:'Garamond-Bold';
}
.progress-nav-container{
    width:20px;
    height:100vh;
    border-right:2px solid var(--color-azul);
}
.progress-nav{
    /*
    background-repeat: no-repeat;
    background-size:100%;*/
    background-color:var(--color-amarillo);
    /*height:10%;*/
}
.nav .nav-logo{
    width:30px;
}
/*RESPONSIVE MOBILE*/
@media screen and (max-width:1200px){
    .nav{
        width:100vw;
        height:70px;
        flex-wrap:wrap;
        background-color: rgba(255,255,255,0.7);
        z-index:6;
    }
    .principal-nav{
        width:100%;
        height:100%;
        flex-direction: row;
        justify-content: space-between;
        border-right:none !important;
    }
    .nav .toggle-icon-container{
        height:100%;
        width:15vw;
    }
    .nav .toggle-icon-container.toggle {
        order:4;
        width:80px;
    }
    .nav .toggle-icon-container svg{
        margin:auto;
        width:30px;
        height:30px;
    }
    
    .nav .logo-container{
        height:100%;
        width:auto;
    }
    .nav .year-container{
        height:100%;
        width:15vw;
        align-items:center;
    }
    .principal-nav h1{
        writing-mode:horizontal-tb;
        text-orientation:initial;
        text-align: center;
    }
    .progress-nav-container{
        width:100vw;
        height:15px;
        border-bottom:2px solid var(--color-azul);
        border-top:2px solid var(--color-azul);
        border-right:none !important;
    }
    .progress-nav-container .progress-nav{
        height:100%;
    }
    .nav .nav-logo{
        width:40px;
        margin:auto;
    }
}
@media screen and (max-width:450px){
    .principal-nav h1{
        font-size:24px;
    }
}